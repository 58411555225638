<template>
  <h2>Contraindications</h2>

  <div class="input-container">
    <label for="deviceContraindications">
      Specify the demographics and clinical conditions of the patients for whom
      the device should not be used:
    </label>
    <input
      id="deviceContraindications"
      v-model="contraindicationsDetails"
      type="text"
      placeholder="Specify contraindications"
    />
  </div>
  <slot />
  <p>{{ getContraindicationsText() }}</p>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import {
  getContraindicationsText,
  useDeviceInfo,
} from "../composables/useDeviceInfo";

const deviceInfo = useDeviceInfo();
const contraindicationsDetails = ref(deviceInfo.value.contraindicationsDetails);

watch(contraindicationsDetails, (newValue) => {
  deviceInfo.value.contraindicationsDetails = newValue;
});
</script>
