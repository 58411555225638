<template>
  <h2>Clinical picture</h2>
  <div v-if="!purpose || !purposeSpecificationDetail">
    <p style="font-size: larger">
      <strong style="color: red">
        Please, complete the device indication section before adding the
        clinical picture.</strong
      >
    </p>
  </div>
  <div v-else>
    <div v-if="showDiagnosisPreventionPredictionFields">
      <div class="input-container">
        <label>
          Specify the symptoms, signs, and factors that are relevant for the
          device for the {{ purpose }} of {{ purposeSpecificationDetail }}:

          <input
            v-model="clinicalPictureDetails"
            type="text"
            placeholder="Specify symptoms, signs, and factors"
          />
        </label>
      </div>
    </div>

    <div v-else>
      <div class="input-container">
        <label>
          Refine the condition specified in the indication by providing
          additional details (e.g., severity, stage, etc.) relevant for the
          device for the
          {{ purpose }} of {{ purposeSpecificationDetail }}:

          <input
            v-model="clinicalPictureDetails"
            type="text"
            placeholder="Refine the condition specified in the indication"
          />
        </label>
      </div>
    </div>

    <div style="padding-top: 30px">
      <div class="input-container">
        <label for="deviceName">
          Specify any additional comorbidity, medical history, or any medical
          information relevant for the safety and effectiveness of the device:
          <input
            v-model="comorbidityDetails"
            type="text"
            placeholder="Specify comorbidities and medical history"
          />
        </label>
      </div>
    </div>
  </div>

  <slot />
  <p>{{ getClinicalPictureDetailsText() }}</p>
  <p>{{ getComorbidityDetailsText() }}</p>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import {
  useDeviceInfo,
  PurposeType,
  getClinicalPictureDetailsText,
  getComorbidityDetailsText,
} from "../composables/useDeviceInfo";

const deviceInfo = useDeviceInfo();
const purpose = computed(() => deviceInfo.value.purpose?.toLowerCase());
const purposeSpecificationDetail = computed(() => {
  return deviceInfo.value.purposeSpecificationDetail.toLowerCase();
});

const clinicalPictureDetails = ref(deviceInfo.value.clinicalPictureDetails);
const comorbidityDetails = ref(deviceInfo.value.comorbidityDetails);

const showDiagnosisPreventionPredictionFields = computed(() => {
  const purposes: PurposeType[] = ["Diagnosis", "Prevention", "Prediction"];
  return purposes.includes(deviceInfo.value.purpose!);
});

watch(clinicalPictureDetails, (newValue) => {
  deviceInfo.value.clinicalPictureDetails = newValue;
});

watch(comorbidityDetails, (newValue) => {
  deviceInfo.value.comorbidityDetails = newValue;
});
</script>
