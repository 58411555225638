<template>
  <h2>Precautions and Warnings</h2>
  <div class="input-container">
    <label for="devicePrecautions">
      Specify other information that is particularly relevant for a user to
      achieve the intended performance and safety of the device:
    </label>
    <input
      id="devicePrecautions"
      v-model="precautionsDetails"
      placeholder="Specify precautions and warnings"
      type="text"
    />
  </div>
  <slot />
  <p>{{ getPrecautionsDetailsText() }}</p>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import {
  getPrecautionsDetailsText,
  useDeviceInfo,
} from "../composables/useDeviceInfo";

const deviceInfo = useDeviceInfo();
const precautionsDetails = ref(deviceInfo.value.precautionsDetails);

watch(precautionsDetails, (newValue) => {
  deviceInfo.value.precautionsDetails = newValue;
});
</script>
