<template>
  <h2>Microbial state status</h2>
  <p>Is the device provided sterile?</p>
  <label>
    <input type="radio" :value="true" v-model="sterile" />
    Yes
  </label>
  <label> <input type="radio" :value="false" v-model="sterile" /> No </label>
  <slot />
  <p>{{ getMicrobialStateStatus() }}</p>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import {
  getMicrobialStateStatus,
  useDeviceInfo,
} from "../composables/useDeviceInfo";

const deviceInfo = useDeviceInfo();

const sterile = ref(deviceInfo.value.microbialStateStatus);

watch(sterile, (newValue) => {
  deviceInfo.value.microbialStateStatus = newValue;
});
</script>
