import { ref } from "vue";

export function useComponentState() {
  const currentComponent = ref("DeviceName");
  const setCurrentComponent = (component: string) => {
    currentComponent.value = component;
  };

  return {
    currentComponent,
    setCurrentComponent,
  };
}
