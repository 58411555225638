<template>
  <div id="app">
    <div class="main">
      <nav class="sidebar">
        <img
          src="./assets/complianceLogo.png"
          alt="App Icon"
          style="padding: 20px"
        />
        <Menu
          :setCurrentComponent="setCurrentComponent"
          :currentComponent="currentComponent"
        />
      </nav>
      <main class="main-content">
        <component :is="selectedComponent">
          <template #default>
            <div class="navigation-buttons">
              <button v-if="hasPrevious" @click="previousComponent">
                Previous
              </button>
              <button
                v-if="hasNext"
                style="margin-left: auto"
                :style="{
                  backgroundColor:
                    skipList.includes(currentComponent) &&
                    canSkip(currentComponent)
                      ? '#f09042'
                      : '',
                }"
                @click="skipNextComponent(currentComponent)"
              >
                {{
                  skipList.includes(currentComponent) &&
                  canSkip(currentComponent)
                    ? "Skip"
                    : "Next"
                }}
              </button>
            </div>
          </template>
        </component>
      </main>
      <aside class="summary">
        <Summary />
      </aside>
    </div>
  </div>
</template>

<script setup lang="ts">
import { provide, ref, watch, computed, ComputedRef } from "vue";
import { useComponentState } from "./composables/useComponentState";
import Menu from "./components/Menu.vue";
import Summary from "./components/Summary.vue";
import Personalization from "./components/Personalization.vue";
import IntendedUser from "./components/IntendedUser.vue";
import DeviceName from "./components/DeviceName.vue";
import Indication from "./components/Indication.vue";
import EnergySuppliedDevice from "./components/EnergySuppliedDevice.vue";
import EnergySuppliedPatient from "./components/EnergySuppliedPatient.vue";
import MicrobialStateStatus from "./components/MicrobialStateStatus.vue";
import Radiation from "./components/Radiation.vue";
import Reprocessing from "./components/Reprocessing.vue";
import { deviceInfo } from "./composables/useDeviceInfo";
import DeviceType from "./components/DeviceType.vue";
import Compatibilities from "./components/Compatibilities.vue";
import VulnerablePopulations from "./components/VulnerablePopulations.vue";
import Demographic from "./components/Demographic.vue";
import ClinicalPicture from "./components/ClinicalPicture.vue";
import Contraindications from "./components/Contraindications.vue";
import PrecautionsAndWarnings from "./components/PrecautionsAndWarnings.vue";
import SubstanceComposition from "./components/SubstanceComposition.vue";
import RemovesSubstances from "./components/RemovesSubstances.vue";
import IncorporatesSubstances from "./components/IncorporatesSubstances.vue";
import AdministersSubstances from "./components/AdministersSubstances.vue";
import Implant from "./components/Implant.vue";
import Invasiveness from "./components/Invasiveness.vue";

const { currentComponent, setCurrentComponent } = useComponentState();
const activeComponent = ref(currentComponent.value);

const skipList = [
  "Demographic",
  "VulnerablePopulations",
  "ClinicalPicture",
  "Contraindications",
  "PrecautionsAndWarnings",
];

const allComponents = {
  DeviceName,
  DeviceType,
  Indication,
  Demographic,
  VulnerablePopulations,
  ClinicalPicture,
  Contraindications,
  PrecautionsAndWarnings,
  IntendedUser,
  Implant,
  Invasiveness,
  Personalization,
  Reprocessing,
  MicrobialStateStatus,
  EnergySuppliedDevice,
  EnergySuppliedPatient,
  Radiation,
  Compatibilities,
  SubstanceComposition,
  IncorporatesSubstances,
  AdministersSubstances,
  RemovesSubstances,
};

type ComponentsType = typeof allComponents;

const components = computed(() => {
  return allComponents;
}) as ComputedRef<ComponentsType>;

provide("deviceInfo", deviceInfo);

type ComponentName = keyof ComponentsType;

const componentList = computed(
  () => Object.keys(components.value) as ComponentName[]
);
const selectedComponent = computed(
  () => components.value[currentComponent.value as ComponentName]
);

const currentIndex = computed(() =>
  componentList.value.indexOf(currentComponent.value as ComponentName)
);
const hasNext = computed(
  () => currentIndex.value < componentList.value.length - 1
);
const hasPrevious = computed(() => currentIndex.value > 0);

function nextComponent() {
  if (hasNext.value) {
    setCurrentComponent(componentList.value[currentIndex.value + 1]);
  }
}

function skipNextComponent(component: string) {
  if (component === "Demographic") deviceInfo.value.skipedDemographics = true;
  else if (component === "VulnerablePopulations")
    deviceInfo.value.skipedVulnerablePopulations = true;
  else if (component === "ClinicalPicture")
    deviceInfo.value.skipedClinicalPicture = true;
  else if (component === "Contraindications")
    deviceInfo.value.skipedContraindicationsDetails = true;
  else if (component === "PrecautionsAndWarnings")
    deviceInfo.value.skipedPrecautionsDetails = true;

  nextComponent();
}

function canSkip(component: string): boolean {
  const {
    demographics,
    vulnerablePopulations,
    clinicalPictureDetails,
    comorbidityDetails,
    contraindicationsDetails,
    precautionsDetails,
  } = deviceInfo.value;

  const conditions: Record<string, boolean> = {
    Demographic: !Object.values(demographics).some((arr) => arr.length > 0),
    VulnerablePopulations: vulnerablePopulations.length === 0,
    ClinicalPicture: !clinicalPictureDetails && !comorbidityDetails,
    Contraindications: !contraindicationsDetails,
    PrecautionsAndWarnings: !precautionsDetails,
  };

  return conditions[component];
}

function previousComponent() {
  if (hasPrevious.value) {
    setCurrentComponent(componentList.value[currentIndex.value - 1]);
  }
}

watch(currentComponent, (newComponent) => {
  if (activeComponent.value !== newComponent) {
    activeComponent.value = newComponent;
  }
});
</script>

<style>
/* Base styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #fff;
}

.header {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  background-color: #2e2e2e;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
}

#app {
  background: #1f1f1f;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.main {
  background: #1f1f1f;
  height: 100vh;
  width: 100%;
  display: flex;
}

.sidebar {
  background-color: #1a1a1a;
  display: flex;
  flex-direction: column;
  width: 320px;
  color: #f5f5f5;
}

.sidebar a {
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 10px 15px;
  border-radius: 4px;
  transition: background-color 0.3s;
  flex: 1;
}

.sidebar a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Main content styles */
.main-content {
  color: #ced4da;
  width: 55%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  overflow-y: auto;
}

/* Summary styles */
.summary {
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  background: #0a0a0a51;
  flex: 1;
}

/* Input container styles */
.input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.input-container label {
  font-weight: 400;
}

h2 {
  width: fit-content;
}
/* Text input styles */

input[type="text"] {
  color: black;
  display: block;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.2s;
}

input[type="text"]:focus {
  outline: none;
  background-color: #fff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

/* Checkbox and radio styles */
input[type="checkbox"],
input[type="radio"] {
  appearance: none;
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  flex-shrink: 0;
  height: 1.25em;
  width: 1.25em;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25em;
  transition: background-color 0.3s, border-color 0.3s, box-shadow 0.2s;
}

/* Checkbox specific styles */
input[type="checkbox"] {
  padding: 0.25em;
}

/* Radio specific styles */
input[type="radio"] {
  border-radius: 50%;
  margin-right: 0.5em;
}

/* Checked state styles */
input[type="checkbox"]:checked,
input[type="radio"]:checked {
  background-color: #6fd7a8;
  border-color: #6fd7a8;
  color: #fff;
}

/* Focus state styles */
input[type="checkbox"]:focus,
input[type="radio"]:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(38, 217, 89, 0.687);
}

/* Label styles */
label {
  display: block;
  padding: 6px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: background-color 0.3s, border-color 0.3s;
}

label:hover {
  background-color: #444;
}

.subsection {
  margin-left: 1rem;
}

@media (max-width: 768px) {
  .sidebar,
  .summary {
    display: none;
  }

  .main-content {
    width: 100%;
    padding: 10px;
  }
}

button {
  padding: 10px 15px;
  font-size: 14px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  color: #333;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.navigation-buttons button {
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  cursor: pointer;
  background-color: #02a36e;
  color: white;
  border: none;
  border-radius: 0.25rem;
  transition: background-color 0.3s ease;
}

.navigation-buttons button:hover:not(:disabled) {
  background-color: #38a169;
}
</style>
