<template>
  <h2>Energy supplied to the patient</h2>
  <p>
    Does the device have a power source other than that generated by the human
    body?
  </p>
  <label>
    <input type="radio" :value="true" v-model="isEnergySuppliedPatient" />
    Yes
  </label>
  <label>
    <input type="radio" :value="false" v-model="isEnergySuppliedPatient" />
    No
  </label>
  <div v-if="isEnergySuppliedPatient">
    <p>The device supplies:</p>
    <label>
      <input type="radio" value="Thermal" v-model="patientEnergy" /> Thermal
    </label>
    <label>
      <input type="radio" value="Electrical" v-model="patientEnergy" />
      Electrical
    </label>
    <label>
      <input type="radio" value="Magnetic" v-model="patientEnergy" /> Magnetic
    </label>
    <label>
      <input type="radio" value="Mechanical" v-model="patientEnergy" />
      Mechanical
    </label>
    <label>
      <input type="radio" value="Acoustic" v-model="patientEnergy" /> Acoustic
    </label>
    <label>
      <input type="radio" value="Other" v-model="patientEnergy" /> Other
      (specify)
    </label>
    <input
      v-if="patientEnergy === 'Other'"
      v-model="patientEnergyOther"
      type="text"
      placeholder="Specify other energy type"
    />
  </div>

  <slot />
  <p>{{ getEnergySuppliedPatient() }}</p>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

import {
  getEnergySuppliedPatient,
  useDeviceInfo,
} from "../composables/useDeviceInfo";

const deviceInfo = useDeviceInfo();

const patientEnergy = ref(deviceInfo.value.energySuppliedPatient || "");
const patientEnergyOther = ref("");
const isEnergySuppliedPatient = ref(deviceInfo.value.isEnergySuppliedPatient);

watch(isEnergySuppliedPatient, (newValue) => {
  deviceInfo.value.isEnergySuppliedPatient = newValue;
  patientEnergy.value = "";
  patientEnergyOther.value = "";
});

watch(patientEnergy, (newValue) => {
  if (newValue === "Other") {
    deviceInfo.value.energySuppliedPatient = patientEnergyOther.value;
  } else {
    deviceInfo.value.energySuppliedPatient = newValue;
  }
});

watch(patientEnergyOther, (newValue) => {
  if (patientEnergy.value === "Other") {
    deviceInfo.value.energySuppliedPatient = newValue;
  }
});
</script>
