<template>
  <h2>Implant</h2>
  <p>
    Is the device an
    <span
      style="text-decoration: underline dashed"
      v-tooltip="
        `implantable device means any device, including those that are partially or wholly absorbed, which is intended:<br> 
- to be totally introduced into the human body, or <br>
- to replace an epithelial surface or the surface of the eye, by clinical intervention and which is intended to remain in place after the procedure.<br> 
Any device intended to be partially introduced into the human body by clinical intervention and intended to remain in place after the procedure for at least 30 days shall also be deemed to be an implantable device.<br>
[EU MDR 2017/745, Chapter I Article 2 (5)]`
      "
      >implant</span
    >?
  </p>
  <label> <input type="radio" :value="true" v-model="implant" /> Yes </label>
  <label> <input type="radio" :value="false" v-model="implant" /> No </label>
  <slot />
  <p>{{ getImplantText() }}</p>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { getImplantText, useDeviceInfo } from "../composables/useDeviceInfo";

const deviceInfo = useDeviceInfo();

const implant = ref(deviceInfo.value.implant);

watch(implant, (newValue) => {
  deviceInfo.value.implant = newValue;
});
</script>
