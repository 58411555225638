<template>
  <h2>Intended user</h2>
  <label>
    <input
      type="checkbox"
      value="Healthcare professional"
      v-model="intendedUser"
    />
    The user of the device is a healthcare professional
  </label>
  <label
    style="text-decoration: underline dashed"
    v-tooltip="
      'Lay person means an individual who does not have formal education in a relevant field of healthcare or medical discipline.[EU MDR 2017/745, Chapter I Article 2 (38)]'
    "
  >
    <input
      type="checkbox"
      value="Lay user"
      v-model="intendedUser"
      :disabled="intendedUser.includes('Patient is also a user')"
    />
    The user of the device is a lay user
  </label>
  <label class="subsection">
    <input
      type="checkbox"
      value="Patient is also a user"
      v-model="intendedUser"
      @change="handlePatientUserChange"
    />
    The patient is also a user
  </label>
  <slot />
  <p>{{ getDeviceIntendedUser() }}</p>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import {
  getDeviceIntendedUser,
  useDeviceInfo,
} from "../composables/useDeviceInfo";

const deviceInfo = useDeviceInfo();

const intendedUser = ref(deviceInfo.value.intendedUser || []);

const handlePatientUserChange = () => {
  if (intendedUser.value.includes("Patient is also a user")) {
    if (!intendedUser.value.includes("Lay user")) {
      intendedUser.value.push("Lay user");
    }
  }
};

watch(
  intendedUser,
  (newVal) => {
    deviceInfo.value.intendedUser = newVal;
  },
  { deep: true }
);
</script>
