<template>
  <div>
    <h2>Device information</h2>
    <p>{{ deviceInfo.getDeviceName() }}</p>
    <p>{{ deviceInfo.getDeviceTypeText() }}</p>
    <p>{{ deviceInfo.getDevicePurpose() }}</p>
    <p v-html="deviceInfo.getDemographics()"></p>
    <p>{{ deviceInfo.getVulnerablePopulations() }}</p>
    <p>{{ deviceInfo.getClinicalPictureDetailsText() }}</p>
    <p>{{ deviceInfo.getComorbidityDetailsText() }}</p>
    <p>{{ deviceInfo.getContraindicationsText() }}</p>
    <p>{{ deviceInfo.getPrecautionsDetailsText() }}</p>
    <p>{{ deviceInfo.getDeviceIntendedUser() }}</p>
    <p>{{ deviceInfo.getImplantText() }}</p>
    <p v-if="deviceInfo.deviceInfo.value.deviceName">
      {{ deviceInfo.getDeviceInvasiveness() }}
    </p>

    <p>{{ deviceInfo.getDevicePersonalization() }}</p>
    <p>{{ deviceInfo.getSingleUse() }}</p>
    <p>{{ deviceInfo.getMicrobialStateStatus() }}</p>
    <p>{{ deviceInfo.getEnergySuppliedDevice() }}</p>
    <p>{{ deviceInfo.getEnergySuppliedPatient() }}</p>
    <p>{{ deviceInfo.getRadiation() }}</p>
    <p>{{ deviceInfo.getDeviceCompatibilitiesText() }}</p>
    <p>{{ deviceInfo.getSubstanceCompositionText() }}</p>
    <p>{{ deviceInfo.getIncorporatesSubstancesText() }}</p>
    <p>{{ deviceInfo.getAdministersSubstancesText() }}</p>
    <p>{{ deviceInfo.getRemovesSubstancesText() }}</p>
    <button
      v-tooltip="tooltipText"
      @click="isDeviceInfoComplete ? generateCoherentParagraph() : ''"
      :class="{ disabled: !isDeviceInfoComplete }"
    >
      
      {{ coherentParagraph.length > 0 ? "Regenerate intended purpose" : "Generate intended purpose" }}
    </button>

    <p v-if="coherentParagraph">{{ coherentParagraph }}</p>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { generateSummary } from "../utils/generateSummary";
import * as deviceInfo from "../composables/useDeviceInfo";

const coherentParagraph = ref<string>("");

const missingFields = computed(() => {
  const info = deviceInfo.deviceInfo.value;
  return {
    deviceName: !info.deviceName,
    indication: !info.purpose && !info.purposeSpecificationDetail,
    demographics: !(
      Object.values(info.demographics).some((arr) => arr.length > 0) ||
      info.skipedDemographics
    ),
    vulnerablePopulations: !(
      info.vulnerablePopulations.length > 0 || info.skipedVulnerablePopulations
    ),
    clinicalPicture: !(
      (info.clinicalPictureDetails && info.comorbidityDetails) ||
      info.skipedClinicalPicture
    ),
    contraindications: !(
      info.contraindicationsDetails || info.skipedContraindicationsDetails
    ),
    PrecautionsAndWarnings: !(
      info.precautionsDetails || info.skipedPrecautionsDetails
    ),

    intendedUser: info.intendedUser.length === 0,
  };
});

const isDeviceInfoComplete = computed(() => {
  const fields = missingFields.value;
  return !Object.values(fields).some((isMissing) => isMissing);
});

const tooltipText = computed(() => {
  const fields = missingFields.value;
  const missing = (Object.keys(fields) as Array<keyof typeof fields>)
    .filter((key) => fields[key])
    .map((key) => `• ${key.replace(/([A-Z])/g, " $1").toLowerCase()}`)
    .join("<br>");

  return missing ? `Complete the following sections:<br>${missing}` : "";
});

const generateCoherentParagraph = async () => {
  try {
    coherentParagraph.value = await generateSummary(deviceInfo.summary.value);
  } catch (error) {
    console.error("Error generating coherent paragraph:", error);
  }
};
</script>

<style scoped>
p {
  margin: 1rem 0 1.5rem;
}

button {
  cursor: pointer;
  background-color: #02a36e;
  color: white;
  border: none;
  border-radius: 0.25rem;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: rgb(41, 117, 77);
}

button.disabled {
  cursor: not-allowed;
  background-color: #c0c0c0;
}
</style>
