<template>
  <h2>Vulnerable populations</h2>

  <p style="margin-bottom: 10px">
    Specify whether the device is intended to be used with a vulnerable
    population:
  </p>
  <label>
    <input
      type="checkbox"
      value="Neonates (1-28 days)"
      v-model="vulnerablePopulations"
    />
    Neonates (1-28 days)
  </label>
  <label>
    <input
      type="checkbox"
      value="Infants (28 days-1 year)"
      v-model="vulnerablePopulations"
    />
    Infants (28 days-1 year)
  </label>
  <label>
    <input
      type="checkbox"
      value="Children (1 year - 18 years)"
      v-model="vulnerablePopulations"
    />
    Children (1 year - 18 years)
  </label>
  <label>
    <input
      type="checkbox"
      value="Elderly patients (65 years and above)"
      v-model="vulnerablePopulations"
    />
    Elderly patients (65 years and above)
  </label>
  <label>
    <input
      type="checkbox"
      value="Pregnant women"
      v-model="vulnerablePopulations"
    />
    Pregnant women
  </label>
  <label>
    <input
      type="checkbox"
      value="Breastfeeding women"
      v-model="vulnerablePopulations"
    />
    Breastfeeding women
  </label>

  <label>
    <input
      type="checkbox"
      value="Immuno-compromised"
      v-model="vulnerablePopulations"
    />
    Immuno-compromised
  </label>

  <label>
    <input
      type="checkbox"
      value="Disabled individuals"
      v-model="isDisabledIndividual"
    />
    Disabled individuals (specify disability)
  </label>

  <input
    v-if="isDisabledIndividual"
    type="text"
    v-model="disabledText"
    placeholder="Specify disability"
  />

  <label>
    <input type="checkbox" value="Other" v-model="isOther" />
    Other vulnerable populations (specify)
  </label>

  <input
    v-if="isOther"
    type="text"
    v-model="otherText"
    placeholder="Specify other"
  />
  <slot />
  <p>{{ getVulnerablePopulations() }}</p>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import {
  getVulnerablePopulations,
  useDeviceInfo,
} from "../composables/useDeviceInfo";

const deviceInfo = useDeviceInfo();

const vulnerablePopulations = ref(deviceInfo.value.vulnerablePopulations || []);

const isDisabledIndividual = ref(false);
const disabledText = ref("");
const isOther = ref(false);
const otherText = ref("");

watch([isDisabledIndividual, disabledText], ([newValue, newDisabledText]) => {
  const prefix = "disability:";
  const searchText = `${prefix} ${newDisabledText.trim()}`;

  const index = deviceInfo.value.vulnerablePopulations.findIndex((entry) =>
    entry.startsWith(prefix)
  );

  if (!newValue) {
    if (index !== -1) {
      deviceInfo.value.vulnerablePopulations.splice(index, 1);
    }
    return;
  }

  if (index !== -1) {
    deviceInfo.value.vulnerablePopulations.splice(index, 1, searchText);
  } else if (newDisabledText.trim() !== "" && searchText !== prefix) {
    deviceInfo.value.vulnerablePopulations.push(searchText);
  }
});

watch([isOther, otherText], ([newValue, newOtherText]) => {
  const prefix = "other vulnerable populations:";
  const searchText = `${prefix} ${newOtherText.trim()}`;

  const index = deviceInfo.value.vulnerablePopulations.findIndex((entry) =>
    entry.startsWith(prefix)
  );

  if (!newValue) {
    if (index !== -1) {
      deviceInfo.value.vulnerablePopulations.splice(index, 1);
    }
    return;
  }

  if (index !== -1) {
    deviceInfo.value.vulnerablePopulations.splice(index, 1, searchText);
  } else if (newOtherText.trim() !== "" && searchText !== prefix) {
    deviceInfo.value.vulnerablePopulations.push(searchText);
  }
});

watch(vulnerablePopulations, (newValue) => {
  deviceInfo.value.vulnerablePopulations = newValue;
});
</script>
