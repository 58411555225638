<template>
  <h2>Demographic</h2>
  <p style="margin-bottom: 10px">
    Specify the patient demographic characteristics relevant to the device:
  </p>
  <div
    v-for="(value, type) in demographics"
    :key="type"
    class="demographic-group"
  >
    <div class="demographic-header">
      <h3>{{ type.replace(/_/g, " ") }}</h3>
      <button @click="showInputField(type)" class="add-button">+</button>
      <input
        style="color: black; padding: 1.5px"
        v-if="showInput[type]"
        v-model="newValue[type]"
        @keyup.enter="addValue(type)"
        @blur="addValue(type)"
        :placeholder="`specify ${type}`"
      />
    </div>

    <div class="flex flex-wrap gap-2 items-center">
      <div
        v-for="(v, index) in value"
        :key="index"
        class="flex items-center text-sm gap-2 px-3 py-15 rounded-full text-black leading-none"
        style="background-color: #02a36e"
      >
        {{ v }}
        <button class="custom-button" @click.stop="removeValue(type, index)">
          <XIcon style="stroke: black" />
        </button>
      </div>
    </div>
  </div>
  <slot />
  <p v-html="getDemographics()" />
</template>

<script setup lang="ts">
import { reactive, ref } from "vue";
import XIcon from "../assets/XIcon.vue";
import {
  Demographics,
  getDemographics,
  useDeviceInfo,
} from "../composables/useDeviceInfo";

const deviceInfo = useDeviceInfo();
const demographics = ref(deviceInfo.value.demographics || "");

const newValue = reactive<Record<keyof Demographics, string>>({
  Age: "",
  Gender: "",
  Weight: "",
  Height: "",
  Ethnicity: "",
  Race: "",
  Nationality: "",
  Language: "",
  Skin_type: "",
  Lifestyle_factor: "",
  Other: "",
});

const showInput = reactive<Record<keyof Demographics, boolean>>({
  Age: false,
  Gender: false,
  Weight: false,
  Height: false,
  Ethnicity: false,
  Race: false,
  Nationality: false,
  Language: false,
  Skin_type: false,
  Lifestyle_factor: false,
  Other: false,
});

const focusedInput = ref<keyof Demographics | null>(null);

const showInputField = (type: keyof Demographics) => {
  for (const key in showInput) {
    if (key !== type) {
      showInput[key as keyof Demographics] = false;
    }
  }

  showInput[type] = true;
  focusedInput.value = type;
};

const addValue = (type: keyof Demographics) => {
  if (newValue[type].trim() !== "") {
    deviceInfo.value.demographics[type].push(newValue[type]);
    newValue[type] = "";
    showInput[type] = false;
    focusedInput.value = null;
  } else {
    newValue[type] = "";
    showInput[type] = false;
    focusedInput.value = null;
  }
};

const removeValue = (type: keyof Demographics, index: number) => {
  deviceInfo.value.demographics[type].splice(index, 1);
};
</script>

<style scoped>
.demographic-group {
  margin-bottom: 4px;
}

.demographic-header {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 4px;
}

.add-button {
  font-size: 1.5em;
  line-height: 0.75em;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  color: #02a36e;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.gap-2 {
  gap: 0.3rem;
}

.text-sm {
  font-size: 0.875rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-15 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.rounded-full {
  border-radius: 9999px;
}

.text-black {
  color: #000;
}

.leading-none {
  line-height: 1;
}

.w-5 {
  width: 1.25rem;
}

.h-5 {
  height: 1.25rem;
}

.custom-button {
  top: -0.375rem; /* -1.5 * 0.25rem */
  right: -0.25rem; /* -1 * 0.25rem */
  line-height: 1;
  border-radius: 0.5rem; /* Large radius */
  border: none;
  background-color: transparent; /* Tailwind bg-blue-600 */
  padding-left: 0.125rem; /* Horizontal padding */
  padding-right: 0.125rem; /* Horizontal padding */
  padding-top: 0.125rem; /* Vertical padding */
  padding-bottom: 0.125rem; /* Vertical padding */
  width: 24px;
  height: 24px;
}
</style>
