<template>
  <h2>Radiation</h2>
  <p>Does the device emit radiation for medical purpose?</p>
  <label><input type="radio" :value="true" v-model="radiation" /> Yes</label>
  <label><input type="radio" :value="false" v-model="radiation" /> No</label>

  <label v-if="radiation">
    <strong>Specify the radiation type:</strong>
    <input v-model="radiationType" type="text" />
  </label>
  <slot />
  <p>{{ getRadiation() }}</p>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { getRadiation, useDeviceInfo } from "../composables/useDeviceInfo";

const deviceInfo = useDeviceInfo();

const radiation = ref(deviceInfo.value.radiation);
const radiationType = ref(deviceInfo.value.radiationType ?? "");

watch(radiation, (newValue) => {
  deviceInfo.value.radiation = newValue;
});

watch(radiationType, (newValue) => {
  deviceInfo.value.radiationType = newValue;
});
</script>
