<template>
  <h2>Device group</h2>

  <p>
    Do you know
    <span
      style="text-decoration: underline dashed"
      v-tooltip="
        'Generic device group means a set of devices having the same or similar intended purposes or a commonality of technology allowing them to be classified in a generic manner not reflecting specific characteristics. <br>[EU MDR 2017/745, Chapter I Article 2 (7)]'
      "
    >
      the generic device group</span
    >
    for your device?
  </p>
  <label>
    <input type="radio" :value="true" v-model="standardizedNomenclature" />
    Yes
  </label>
  <label>
    <input type="radio" :value="false" v-model="standardizedNomenclature" />
    No
  </label>

  <div v-if="standardizedNomenclature">
    <div class="input-container">
      <label for="deviceType"> What is the generic device group? </label>
      <input
        id="deviceType"
        v-model="deviceType"
        type="text"
        placeholder="Specify the device group"
      />
    </div>
  </div>

  <slot></slot>
  <p>{{ getDeviceTypeText() }}</p>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { getDeviceTypeText, useDeviceInfo } from "../composables/useDeviceInfo";

const deviceInfo = useDeviceInfo();

const standardizedNomenclature = ref<boolean | undefined>(
  deviceInfo.value.standardizedNomenclature ?? undefined
);
const deviceType = ref(deviceInfo.value.deviceType ?? "");
const isAccessory = ref(deviceInfo.value.isAccessory);

const deviceOptions = [
  "Instrument",
  "Software",
  "AI system",
  "Implement / Equipment / Tool",
  "Implant",
  "Material",
  "Reagent",
  "Substance (biological substances, gases, reagent, other substance)",
];

watch(deviceType, (newValue) => {
  console.log(newValue);
  deviceInfo.value.deviceType = newValue;
});

watch(standardizedNomenclature, (newValue) => {
  deviceInfo.value.standardizedNomenclature = newValue;
  deviceInfo.value.deviceTypeOptions = [];
  deviceType.value = "";
  deviceInfo.value.deviceType = "";
});

watch(isAccessory, (newValue) => {
  deviceInfo.value.isAccessory = newValue;
});
</script>
