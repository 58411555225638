<template>
  <h2>
    Devices composed of
    <span
      style="text-decoration: underline dashed"
      v-tooltip="
        'Substance: Any matter irrespective of origin which may be: human, e.g. human blood and human blood products; animal, e.g. micro-organisms, whole animals, parts of organs, animal secretions, toxins, extracts, blood products; vegetable, e.g. micro-organisms, plants, parts of plants, vegetable secretions, extracts; chemical, e.g. elements, naturally occurring chemical materials and chemical products obtained by chemical change or synthesis.<br>[DIRECTIVE 2001/83/EC]'
      "
      >substances</span
    >
  </h2>

  <p>
    Is the device composed of substances (or combinations of substances) that
    are introduced into the body or applied to the skin, and that are absorbed
    by or locally dispersed in the body?
  </p>
  <label>
    <input type="radio" :value="true" v-model="substanceComposition" />
    Yes
  </label>
  <label>
    <input type="radio" :value="false" v-model="substanceComposition" />
    No
  </label>

  <div v-if="substanceComposition">
    <p>The device is composed of the following substance:</p>
    <label>
      <input
        type="radio"
        v-model="compositionDetails"
        value="Non-viable tissues or cells, or their derivatives, of human origin"
      />
      <span
        style="text-decoration: underline dashed"
        v-tooltip="
          'Non-viable means having no potential for metabolism or multiplication.<br>[EU MDR 2017/745, Chapter I Article 2 (16)]'
        "
        >Non-viable</span
      >
      tissues or cells, or their derivatives, of human origin
    </label>
    <label>
      <input
        type="radio"
        v-model="compositionDetails"
        value="Non-viable tissues or cells, or their derivatives, of animal origin"
      />
      Non-viable tissues or cells, or their derivatives, of animal origin
    </label>
    <label>
      <input
        type="radio"
        v-model="compositionDetails"
        value="Other non viable substances of biological origins (e.g., bacteria, fungi, viruses)"
      />
      Other non viable substances of biological origins (e.g., bacteria, fungi,
      viruses)
    </label>
    <label>
      <input type="radio" v-model="compositionDetails" value="Gases" />
      Gases
    </label>
    <label>
      <input
        type="radio"
        v-model="compositionDetails"
        value="Other substances"
      />
      Other substances
    </label>

    <div class="input-container" v-if="compositionDetails">
      <input
        v-model="compositionSubstance"
        placeholder="Specify substance"
        type="text"
      />
    </div>
  </div>

  <slot />
  <p>{{ getSubstanceCompositionText() }}</p>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import {
  getSubstanceCompositionText,
  useDeviceInfo,
} from "../composables/useDeviceInfo";

const deviceInfo = useDeviceInfo();

const substanceComposition = ref(deviceInfo.value.substanceComposition);
const compositionDetails = ref(deviceInfo.value.compositionDetails || "");
const compositionSubstance = ref(deviceInfo.value.compositionSubstance || "");

watch(substanceComposition, (newValue) => {
  deviceInfo.value.substanceComposition = newValue;
  compositionDetails.value = "";
  compositionSubstance.value = "";
});

watch(compositionDetails, (newValue) => {
  deviceInfo.value.compositionDetails = newValue;
});

watch(compositionSubstance, (newValue) => {
  deviceInfo.value.compositionSubstance = newValue;
});
</script>
