<template>
  <h2>Personalization</h2>
  <label
    style="text-decoration: underline dashed"
    v-tooltip="
      `Custom-made device means any device specifically made in accordance with a written prescription of any person authorised by national law by virtue of that person's professional qualifications which gives, under that person's responsibility, specific design characteristics, and is intended for the sole use of a particular patient exclusively to meet their individual conditions and needs.<br>[EU MDR 2017/745, Chapter I, Article 2 (3)]`
    "
  >
    <input type="radio" value="Custom-made" v-model="devicePersonalization" />
    Custom-made
  </label>
  <label
    v-tooltip="
      `Adaptable medical device - a medical device that meets the following requirements:<br> - it is mass-produced; and <br>- it is adapted, adjusted, assembled or shaped at the point of care, in accordance with the manufacturer's validated instructions, to suit an individual patient's specific anatomo-physiologic features prior to use.<br>[IMDRF/PMD WG/N74 FINAL: 2023]`
    "
    style="text-decoration: underline dashed"
  >
    <input type="radio" value="Adaptable" v-model="devicePersonalization" />
    Adaptable
  </label>

  <label
    v-tooltip="
      `Patient-matched Medical Device - A medical device that meets the following requirements: <br>
- it is matched to a patient's anatomy within a specified design envelope using techniques such as scaling of the device based on anatomic references, or by using the full anatomic features from patient imaging; and <br>
- it is typically produced in a batch through a process that is capable of being validated and reproduced; and <br>
- it is designed and produced under the responsibility of a manufacturer even though the design may be developed in consultation with an authorized healthcare professional.<br>
Note 1: A written request from an authorized healthcare professional may be present; but is not
mandatory.<br>
Note 2: The number and type of design inputs in consultation with a healthcare professional may vary
depending on the medical devices to be manufactured.<br>
Note 3: The design must remain within the validated parameters of the specified design envelope.<br>
[IMDRF/PMD WG/N74 FINAL: 2023]`
    "
    style="text-decoration: underline dashed"
  >
    <input
      type="radio"
      value="Patient-matched"
      v-model="devicePersonalization"
    />
    Patient-matched
  </label>
  <label>
    <input
      type="radio"
      value="None of the above"
      v-model="devicePersonalization"
    />
    None of the above
  </label>
  <slot />
  <p>{{ getDevicePersonalization() }}</p>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import {
  getDevicePersonalization,
  useDeviceInfo,
} from "../composables/useDeviceInfo";

const deviceInfo = useDeviceInfo();
const devicePersonalization = ref(deviceInfo.value.personalization || "");

watch(devicePersonalization, (newValue) => {
  deviceInfo.value.personalization = newValue;
});
</script>
