<template>
  <h2
    style="text-decoration: underline dashed; width: fit-content"
    v-tooltip="
      `Compatibility is the ability of a device, including software, when used together with one or more other devices in accordance with its intended purpose, to:<br>
(a) perform without losing or compromising the ability to perform as intended, and/or <br>
(b) integrate and/or operate without the need for modification or adaption of any part of the combined devices, and/or<br> 
(c) be used together without conflict/interference or adverse reaction.<br>[EU MDR 2017/745, Chapter I Article 2 (25)]`
    "
  >
    Compatibilities
  </h2>

  <p>
    Is the device intended for use in combination with other devices or
    equipment, or within a specific medical procedure?
  </p>
  <label>
    <input type="radio" :value="true" v-model="hasCompatibilities" />
    Yes
  </label>
  <label>
    <input type="radio" :value="false" v-model="hasCompatibilities" />
    No
  </label>

  <div v-if="hasCompatibilities">
    <div class="input-container">
      <label for="compatibilities">Specify:</label>
      <input
        v-model="compatibilities"
        type="text"
        placeholder="Compatibilities"
      />
    </div>
  </div>
  <slot />
  <p>{{ getDeviceCompatibilitiesText() }}</p>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import {
  getDeviceCompatibilitiesText,
  useDeviceInfo,
} from "../composables/useDeviceInfo";

const deviceInfo = useDeviceInfo();
const hasCompatibilities = ref(deviceInfo.value.hasCompatibilities);
const compatibilities = ref(deviceInfo.value.compatibilities ?? "");
watch(hasCompatibilities, (newValue) => {
  deviceInfo.value.hasCompatibilities = newValue;
  deviceInfo.value.compatibilities = "";
  compatibilities.value = "";
});

watch(compatibilities, (newValue) => {
  if (hasCompatibilities.value) {
    deviceInfo.value.compatibilities = newValue;
  } else {
    deviceInfo.value.compatibilities = "";
  }
});
</script>
