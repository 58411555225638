<template>
  <div class="container">
    <div class="purpose-selection">
      <h2>Indication</h2>
      <p style="font-size: medium">
        <strong style="color: red">
          Currently, only one indication is allowed.</strong
        >
      </p>
      <p>Select the device purpose:</p>

      <div>
        <p><strong>Therapeutic / Diagnostic purposes:</strong></p>
        <label>
          <input
            type="radio"
            name="purpose"
            value="Treatment"
            v-model="purpose"
            @change="updateDetailsPosition"
          />
          Treatment
        </label>
        <label>
          <input
            type="radio"
            name="purpose"
            value="Alleviation"
            v-model="purpose"
            @change="updateDetailsPosition"
          />
          Alleviation
        </label>
        <label>
          <input
            type="radio"
            name="purpose"
            value="Compensation"
            v-model="purpose"
            @change="updateDetailsPosition"
          />
          Compensation
        </label>
        <label>
          <input
            type="radio"
            name="purpose"
            value="Replacement"
            v-model="purpose"
            @change="updateDetailsPosition"
          />
          Replacement
        </label>
        <label>
          <input
            type="radio"
            name="purpose"
            value="Modification"
            v-model="purpose"
            @change="updateDetailsPosition"
          />
          Modification
        </label>
        <label>
          <input
            type="radio"
            name="purpose"
            value="Diagnosis"
            v-model="purpose"
            @change="updateDetailsPosition"
          />
          Diagnosis
        </label>
        <label>
          <input
            type="radio"
            name="purpose"
            value="Prevention"
            v-model="purpose"
            @change="updateDetailsPosition"
          />
          Prevention
        </label>
        <label>
          <input
            type="radio"
            name="purpose"
            value="Monitoring"
            v-model="purpose"
            @change="updateDetailsPosition"
          />
          Monitoring
        </label>
        <label>
          <input
            type="radio"
            name="purpose"
            value="Prediction"
            v-model="purpose"
            @change="updateDetailsPosition"
          />
          Prediction
        </label>
        <label>
          <input
            type="radio"
            name="purpose"
            value="Prognosis"
            v-model="purpose"
            @change="updateDetailsPosition"
          />
          Prognosis
        </label>
        <label>
          <input
            type="radio"
            name="purpose"
            value="Investigation"
            v-model="purpose"
            @change="updateDetailsPosition"
          />
          Investigation
        </label>
      </div>

      <div>
        <p><strong>Other purposes:</strong></p>
        <label>
          <input
            type="radio"
            name="purpose"
            value="Control of conception"
            v-model="purpose"
            @change="updateDetailsPosition"
          />
          Control of conception
        </label>
        <label>
          <input
            type="radio"
            name="purpose"
            value="Support of conception"
            v-model="purpose"
            @change="updateDetailsPosition"
          />
          Support of conception
        </label>
        <label>
          <input
            type="radio"
            name="purpose"
            value="Cleaning devices"
            v-model="purpose"
            @change="updateDetailsPosition"
          />
          Cleaning devices
        </label>
        <label>
          <input
            type="radio"
            name="purpose"
            value="Disinfection of device"
            v-model="purpose"
            @change="updateDetailsPosition"
          />
          Disinfection of device
        </label>
        <label>
          <input
            type="radio"
            name="purpose"
            value="Sterilization of devices"
            v-model="purpose"
            @change="updateDetailsPosition"
          />
          Sterilization of devices
        </label>
      </div>

      <div class="purpose-details" v-if="showPurpose" ref="detailsDiv">
        <p>
          <strong>Specify what is {{ getPurposeVerb(purpose) }}:</strong>
        </p>
        <div v-if="['Treatment', 'Alleviation', 'Diagnosis'].includes(purpose)">
          <label>
            <input type="radio" value="Disease" v-model="specification" />
            Disease
          </label>
          <label>
            <input type="radio" value="Injury" v-model="specification" /> Injury
          </label>
          <label>
            <input type="radio" value="Disability" v-model="specification" />
            Disability
          </label>
        </div>
        <div v-if="purpose === 'Monitoring'">
          <label>
            <input type="radio" value="Disease" v-model="specification" />
            Disease
          </label>
          <label>
            <input type="radio" value="Injury" v-model="specification" /> Injury
          </label>
          <label>
            <input type="radio" value="Disability" v-model="specification" />
            Disability
          </label>
          <label>
            <input type="radio" value="Vital sign" v-model="specification" />
            Vital sign
          </label>
          <label>
            <input
              type="radio"
              value="Clinical parameter"
              v-model="specification"
            />
            Clinical parameter
          </label>
        </div>
        <div v-if="['Prevention', 'Prognosis', 'Prediction'].includes(purpose)">
          <label>
            <input type="radio" value="Disease" v-model="specification" />
            Disease
          </label>
        </div>
        <div v-if="purpose === 'Compensation'">
          <label>
            <input type="radio" value="Injury" v-model="specification" /> Injury
          </label>
          <label>
            <input type="radio" value="Disability" v-model="specification" />
            Disability
          </label>
        </div>
        <div
          v-if="
            ['Investigation', 'Replacement', 'Modification'].includes(purpose)
          "
        >
          <label>
            <input type="radio" value="Anatomy" v-model="specification" />
            Anatomy
          </label>
          <label>
            <input
              type="radio"
              value="Physiological process"
              v-model="specification"
            />
            Process
          </label>
          <label>
            <input
              type="radio"
              value="Pathological process"
              v-model="specification"
            />
            Pathological process
          </label>
        </div>
        <label v-if="specification">
          <strong>Specify the {{ specification.toLowerCase() }}:</strong>
          <input v-model="detail" type="text" />
        </label>
      </div>
    </div>
  </div>
  <slot />
  <p>{{ getDevicePurpose() }}</p>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, nextTick, computed } from "vue";

import {
  useDeviceInfo,
  PurposeType,
  getDevicePurpose,
} from "../composables/useDeviceInfo";

const deviceInfo = useDeviceInfo();

type SpecificationType = string[];

const updateDetailsPosition = async () => {
  await nextTick();
  if (detailsDiv.value) {
    const selectedInput = document.querySelector(
      'input[name="purpose"]:checked'
    );
    if (selectedInput) {
      const rect = (selectedInput as HTMLElement).getBoundingClientRect();
      detailsDiv.value.style.position = "absolute";
      detailsDiv.value.style.top = `${94}px`;
      detailsDiv.value.style.left = `${rect.right + 10}px`;
    }
  }
};

const purpose = ref<PurposeType | "">(deviceInfo.value.purpose ?? "");
const specification = ref<string>(deviceInfo.value.specification ?? "");
const detail = ref<string>(deviceInfo.value.purposeSpecificationDetail ?? "");
const detailsDiv = ref<HTMLElement | null>(null);
updateDetailsPosition();

const showPurpose = computed(() => {
  const otherPurposes: PurposeType[] = [
    "Control of conception",
    "Support of conception",
    "Cleaning devices",
    "Disinfection of device",
    "Sterilization of devices",
  ];

  return !!purpose.value && !otherPurposes.includes(purpose.value);
});

const specificationsMap: Record<PurposeType, SpecificationType> = {
  Treatment: ["Disease", "Injury", "Disability"],
  Alleviation: ["Disease", "Injury", "Disability"],
  Diagnosis: ["Disease", "Injury", "Disability"],
  Monitoring: [
    "Disease",
    "Injury",
    "Disability",
    "Vital sign",
    "Clinical parameter",
  ],
  Prevention: ["Disease"],
  Prognosis: ["Disease"],
  Prediction: ["Disease"],
  Compensation: ["Injury", "Disability"],
  Investigation: ["Anatomy", "Physiological process", "Pathological process"],
  Replacement: ["Anatomy", "Physiological process", "Pathological process"],
  Modification: ["Anatomy", "Physiological process", "Pathological process"],
  "Control of conception": [],
  "Support of conception": [],
  "Cleaning devices": [],
  "Disinfection of device": [],
  "Sterilization of devices": [],
};
const purposeVerbMap: Record<PurposeType, string> = {
  Treatment: "treated",
  Alleviation: "alleviated",
  Compensation: "compensated",
  Replacement: "replaced",
  Modification: "modified",
  Diagnosis: "diagnosed",
  Prevention: "prevented",
  Prediction: "predicted",
  Prognosis: "prognosed",
  Monitoring: "monitored",
  Investigation: "investigated",
  "Control of conception": "controlled",
  "Support of conception": "supported",
  "Cleaning devices": "cleaned",
  "Disinfection of device": "disinfected",
  "Sterilization of devices": "sterilized",
};

const getPurposeVerb = (purpose: PurposeType | ""): string => {
  if (purpose === "") {
    return "";
  }
  return purposeVerbMap[purpose] || purpose.toLowerCase();
};

watch(purpose, (newValue) => {
  if (newValue) {
    updateDetailsPosition();
    deviceInfo.value.purpose = newValue;
    specification.value = "";
    detail.value = "";
    const specs = specificationsMap[newValue as PurposeType];
    if (specs && specs.length === 1) {
      specification.value = specs[0];
    }
  }
});

watch(specification, (newValue) => {
  deviceInfo.value.specification = newValue;
});

watch(detail, (newValue) => {
  deviceInfo.value.purposeSpecificationDetail = newValue;
});

onMounted(() => {
  window.addEventListener("resize", updateDetailsPosition);
});
</script>

<style scoped>
.container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

h2,
p {
  margin: 6px 0;
}

.purpose-selection {
  display: flex;
  flex-direction: column;
}

.purpose-details {
  margin-top: 10px;
  padding-left: 10px;
  width: 60%;
}
</style>
