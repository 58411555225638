<template>
  <h2
    style="text-decoration: underline dashed"
    v-tooltip="
      'Reprocessing means a process carried out on a used device in order to allow its safe reuse including cleaning, disinfection, sterilisation and related procedures, as well as testing and restoring the technical and functional safety of the used device.<br>[EU MDR 2017/745, Chapter I Article 2 (39)]'
    "
  >
    Reprocessing
  </h2>
  <p>
    Is the device<span
      style="text-decoration: underline dashed"
      v-tooltip="
        'Single-use device means a device that is intended to be used on one individual during a single procedure.<br>[EU MDR 2017/745, Chapter I Article 2 (8)]'
      "
    >
      single-use</span
    >?
  </p>
  <label>
    <input type="radio" :value="true" v-model="isSingleUse" />
    Yes
  </label>
  <label>
    <input type="radio" :value="false" v-model="isSingleUse" />
    No
  </label>
  <slot />
  <p>{{ getSingleUse() }}</p>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { getSingleUse, useDeviceInfo } from "../composables/useDeviceInfo";

const deviceInfo = useDeviceInfo();

const isSingleUse = ref(deviceInfo.value.isSingleUse);

watch(isSingleUse, (newValue) => {
  deviceInfo.value.isSingleUse = newValue;
});
</script>
