<template>
  <h2
    style="text-decoration: underline dashed"
    v-tooltip="
      'Invasive device means any device which, in whole or in part, penetrates inside the body, either through a body orifice or through the surface of the body.<br>[EU MDR 2017/745, Chapter I Article 2 (6)]'
    "
  >
    Invasiveness
  </h2>
  <label>
    <input
      type="radio"
      value="The device is not invasive"
      v-model="invasiveness"
    />
    The device is not invasive
  </label>
  <label>
    <input
      type="radio"
      value="The device is invasive but not surgically invasive"
      v-model="invasiveness"
    />
    The device is invasive but not surgically invasive
  </label>
  <label>
    <input
      type="radio"
      value="The device is surgically invasive"
      v-model="invasiveness"
    />
    The device is
    <span
      style="text-decoration: underline dashed"
      v-tooltip="
        `Surgically invasive device means:<br>
(a) an invasive device which penetrates inside the body through the surface of the body, including through mucous membranes of body orifices with the aid or in the context of a surgical operation; and<br>
(b) a device which produces penetration other than through a body orifice.<br>[EU MDR 2017/745, Annex VIII Chapter I (2.2)]`
      "
    >
      surgically invasive</span
    >
  </label>
  <slot />
  <p>{{ getDeviceInvasiveness() }}</p>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import {
  getDeviceInvasiveness,
  useDeviceInfo,
} from "../composables/useDeviceInfo";

const deviceInfo = useDeviceInfo();

const invasiveness = ref(deviceInfo.value.invasiveness);

watch(invasiveness, (newVal) => {
  deviceInfo.value.invasiveness = newVal;
});
</script>
