import { createApp } from "vue";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/animations/shift-away.css";
import App from "./App.vue";
import tippy, { Instance } from "tippy.js";

const app = createApp(App);
app.directive("tooltip", {
  created: (el, { value, modifiers }) => {
    let placement: "right" | "left" | "top" | "bottom" | undefined;
    if (modifiers.right) {
      placement = "right";
    }
    if (modifiers.left) {
      placement = "left";
    }
    if (modifiers.top) {
      placement = "top";
    }
    if (modifiers.bottom) {
      placement = "bottom";
    }
    el.$tippy = tippy(el, {
      content: value,
      maxWidth: 500,
      placement,
      interactive: modifiers.interactive ?? false,
      theme: modifiers.light ? "light" : "",
      zIndex: 999999999999,
      allowHTML: true,
    });
    if (!value) {
      (el.$tippy as Instance).disable();
    }
  },
  updated: (el, { value }) => {
    (el.$tippy as Instance).setContent(value);
    if (!value) {
      (el.$tippy as Instance).disable();
    } else {
      (el.$tippy as Instance).enable();
    }
  },
  beforeUnmount(el) {
    (el.$tippy as Instance).destroy();
  },
});
app.mount("#app");
