<template>
  <h2>Device name</h2>
  <div class="input-container">
    <label for="deviceName"> What is the device trade name?</label>
    <input
      id="deviceName"
      v-model="deviceName"
      type="text"
      placeholder="Device name"
    />
    <slot></slot>
    <p>{{ getDeviceName() }}</p>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { getDeviceName, useDeviceInfo } from "../composables/useDeviceInfo";

const deviceInfo = useDeviceInfo();
const deviceName = ref(deviceInfo.value.deviceName || "");

watch(deviceName, (newValue) => {
  deviceInfo.value.deviceName = newValue;
});
</script>
