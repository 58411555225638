<template>
  <div class="menu">
    <div v-for="(item, index) in sortedMenuItems" :key="item.name">
      <button
        @click="handleClick(item)"
        :class="{
          active: isActive(item),
          required: item.isRequired,
          clickable: isClickable(item),
          completed: item.completed,
        }"
        :style="{
          backgroundColor: item.completed ? '#38a169' : '',
          cursor: item.completed ? 'pointer' : '',
        }"
      >
        {{ item.name }}
      </button>
      <div class="submenu" v-if="item.submenu && item.submenu.length">
        <div
          v-for="(subItem, subIndex) in sortedSubMenuItems(item)"
          :key="subItem.name"
        >
          <button
            @click="handleClick(subItem)"
            :class="{
              active: isActive(subItem),
              required: subItem.isRequired,
              clickable: isClickable(subItem),
            }"
            :style="{
              backgroundColor: subItem.completed ? '#38a169' : '',
              cursor: subItem.completed ? 'pointer' : '',
            }"
          >
            {{ subItem.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { deviceInfo } from "../composables/useDeviceInfo";

const menuItems = computed<MenuItem[]>(() => [
  {
    name: "Device name",
    component: "DeviceName",
    isRequired: true,
    completed: !!deviceInfo.value.deviceName,
    order: 1,
  },
  {
    order: 2,
    name: "Device group",
    component: "DeviceType",
    completed:
      deviceInfo.value.standardizedNomenclature === false ||
      (!!deviceInfo.value.deviceType &&
        !!deviceInfo.value.standardizedNomenclature),
  },
  {
    order: 3,
    name: "Indication",
    component: "Indication",
    isRequired: true,
    completed:
      !!deviceInfo.value.purpose &&
      !!deviceInfo.value.purposeSpecificationDetail,
  },
  {
    name: "Target population",
    submenu: [
      {
        order: 4,
        name: "Demographic",
        component: "Demographic",
        isRequired: true,
        completed:
          Object.values(deviceInfo.value.demographics).some(
            (arr) => arr.length > 0
          ) || deviceInfo.value.skipedDemographics,
      },
      {
        order: 5,
        name: "Vulnerable populations",
        component: "VulnerablePopulations",
        isRequired: true,
        completed:
          deviceInfo.value.vulnerablePopulations.length > 0 ||
          deviceInfo.value.skipedVulnerablePopulations,
      },
      {
        order: 6,
        name: "Clinical picture",
        component: "ClinicalPicture",
        isRequired: true,
        completed:
          (!!deviceInfo.value.clinicalPictureDetails &&
            !!deviceInfo.value.comorbidityDetails) ||
          deviceInfo.value.skipedClinicalPicture,
      },
      {
        order: 7,
        name: "Contraindications",
        component: "Contraindications",
        isRequired: true,
        completed:
          !!deviceInfo.value.contraindicationsDetails ||
          deviceInfo.value.skipedContraindicationsDetails,
      },
      {
        order: 8,
        name: "Precautions and warnings",
        component: "PrecautionsAndWarnings",
        isRequired: true,
        completed:
          !!deviceInfo.value.precautionsDetails ||
          deviceInfo.value.skipedPrecautionsDetails,
      },
    ],
    order: 4,
  },
  {
    name: "Intended user",
    component: "IntendedUser",
    isRequired: true,
    completed: deviceInfo.value.intendedUser.length > 0,
    order: 9,
  },
  {
    name: "Implant",
    component: "Implant",
    completed: deviceInfo.value.implant !== undefined,
    order: 10,
  },
  {
    name: "Invasiveness",
    component: "Invasiveness",
    completed: !!deviceInfo.value.invasiveness,
    order: 11,
  },
  {
    name: "Personalization",
    component: "Personalization",
    completed: !!deviceInfo.value.personalization,
    order: 12,
  },
  {
    name: "Reprocessing",
    component: "Reprocessing",
    completed: deviceInfo.value.isSingleUse !== undefined,
    order: 13,
  },
  {
    name: "Microbial state status",
    component: "MicrobialStateStatus",
    completed: deviceInfo.value.microbialStateStatus !== undefined,
    order: 14,
  },
  {
    name: "Power source",
    component: "EnergySuppliedDevice",
    completed:
      deviceInfo.value.isEnergySuppliedDevice === false ||
      (deviceInfo.value.isEnergySuppliedDevice &&
        !!deviceInfo.value.energySuppliedDevice),
    order: 15,
  },
  {
    name: "Energy supplied to the patient",
    component: "EnergySuppliedPatient",
    completed:
      deviceInfo.value.isEnergySuppliedPatient === false ||
      (deviceInfo.value.isEnergySuppliedPatient &&
        !!deviceInfo.value.energySuppliedPatient),
    order: 16,
  },
  {
    name: "Radiation",
    component: "Radiation",
    completed:
      deviceInfo.value.radiation === false ||
      (deviceInfo.value.radiation && !!deviceInfo.value.radiationType),
    order: 17,
  },
  {
    name: "Compatibilities",
    component: "Compatibilities",
    completed:
      deviceInfo.value.hasCompatibilities === false ||
      (deviceInfo.value.hasCompatibilities &&
        !!deviceInfo.value.compatibilities),
    order: 18,
  },
  {
    name: "Substances",
    order: 19,
    submenu: [
      {
        order: 19,

        name: "Devices composed of substances",
        component: "SubstanceComposition",
        completed:
          deviceInfo.value.substanceComposition === false ||
          (deviceInfo.value.substanceComposition &&
            !!deviceInfo.value.compositionSubstance &&
            !!deviceInfo.value.compositionDetails),
      },
      {
        order: 20,

        name: "Devices incorporating substances",
        component: "IncorporatesSubstances",
        completed:
          deviceInfo.value.incorporatesSubstances === false ||
          (deviceInfo.value.incorporatesSubstances &&
            !!deviceInfo.value.incorporationSubstance &&
            !!deviceInfo.value.incorporationDetails),
      },
      {
        order: 21,

        name: "Devices administering substances",
        component: "AdministersSubstances",
        completed:
          deviceInfo.value.administersSubstances === false ||
          (deviceInfo.value.administersSubstances &&
            !!deviceInfo.value.administrationDetails &&
            !!deviceInfo.value.administrationSubstance),
      },
      {
        order: 22,

        name: "Devices removing substances",
        component: "RemovesSubstances",
        completed:
          deviceInfo.value.removesSubstances === false ||
          (deviceInfo.value.removesSubstances &&
            !!deviceInfo.value.removalSubstance &&
            !!deviceInfo.value.removalDetails),
      },
    ],
  },
]);

interface MenuItem {
  name: string;
  component?: string;
  isRequired?: boolean;
  submenu?: MenuItem[];
  completed?: boolean;
  order: number;
}

const sortedMenuItems = computed(() => {
  return menuItems.value.sort((a, b) => a.order - b.order);
});

const sortedSubMenuItems = (item: MenuItem) => {
  return item.submenu ? item.submenu.sort((a, b) => a.order - b.order) : [];
};

const props = defineProps<{
  setCurrentComponent: (component: string) => void;
  currentComponent: string;
}>();

const activeComponent = ref(props.currentComponent);

const currentIndex = computed(() => {
  let index = -1;
  sortedMenuItems.value.some((item, i) => {
    if (item.component === activeComponent.value) {
      index = item.order;
      return true;
    }
    if (item.submenu) {
      const subIndex = item.submenu.findIndex(
        (subItem) => subItem.component === activeComponent.value
      );
      if (subIndex !== -1) {
        index = item.order;
        return true;
      }
    }
    return false;
  });
  return index;
});

const isActive = (item: MenuItem) => {
  return item.component === activeComponent.value;
};

const isClickable = (item: MenuItem) => {
  const currentIndexValue = currentIndex.value;

  return item.order <= currentIndexValue || item.completed;
};

const handleClick = (item: MenuItem) => {
  if (item.order <= currentIndex.value || item.completed) {
    if (props.setCurrentComponent) {
      if (item.submenu && item.submenu.length) {
        props.setCurrentComponent(item.submenu[0].component!);
        activeComponent.value = item.submenu[0].component!;
      } else if (item.component) {
        props.setCurrentComponent(item.component);
        activeComponent.value = item.component;
      }
    }
  }
};

watch(
  () => props.currentComponent,
  (newComponent) => {
    activeComponent.value = newComponent;
  }
);
</script>

<style scoped>
.menu {
  width: 100%;
  height: 100%;
  color: #f5f5f5;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: column;
  gap: 1.5px;
  overflow-y: auto;
}

button {
  padding: 6px 15px;
  font-size: 14px;
  border: none;
  background-color: transparent;
  color: #f5f5f5;
  text-align: left;
  transition: background-color 0.3s ease, color 0.3s ease;
  width: 100%;
}

button.clickable:hover {
  background-color: #333;
  cursor: pointer;
}

button:focus {
  outline: none;
}

button.active {
  background-color: #333;
  color: #fff;
}

button.required::after {
  content: " *";
  color: red;
}

.submenu {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 1.5px;
}
</style>
