<template>
  <h2>
    Devices administering
    <span
      style="text-decoration: underline dashed"
      v-tooltip="
        'Substance: Any matter irrespective of origin which may be: human, e.g. human blood and human blood products; animal, e.g. micro-organisms, whole animals, parts of organs, animal secretions, toxins, extracts, blood products; vegetable, e.g. micro-organisms, plants, parts of plants, vegetable secretions, extracts; chemical, e.g. elements, naturally occurring chemical materials and chemical products obtained by chemical change or synthesis.<br>[DIRECTIVE 2001/83/EC]'
      "
      >substances</span
    >
  </h2>

  <p>Does the device administer substances?</p>
  <label>
    <input type="radio" :value="true" v-model="administersSubstances" />
    Yes
  </label>
  <label>
    <input type="radio" :value="false" v-model="administersSubstances" />
    No
  </label>

  <div v-if="administersSubstances">
    <p>The device administers the following substance:</p>
    <label>
      <input
        type="radio"
        v-model="administrationDetails"
        value="Medicinal products (including substances derived from human blood, or human plasma)"
      />
      <span
        style="text-decoration: underline dashed"
        v-tooltip="
          `Medicinal product:<br>
Any substance or combination of substances presented for treating or preventing disease in human beings.<br>
Any substance or combination of substances which may be administered to human beings with a view to making a medical diagnosis or to restoring, correcting or modifying physiological functions in human beings is likewise considered a medicinal product.<br>[DIRECTIVE 2001/83/EC]`
        "
        >Medicinal products</span
      >
      (including substances derived from human blood, or human plasma)
    </label>
    <label>
      <input
        type="radio"
        v-model="administrationDetails"
        value="Non-viable tissues or cells, or their derivatives, of human origin"
      />

      <span
        style="text-decoration: underline dashed"
        v-tooltip="
          'Non-viable means having no potential for metabolism or multiplication.<br>[EU MDR 2017/745, Chapter I Article 2 (16)]'
        "
        >Non-viable</span
      >
      tissues or cells, or their derivatives, of human origin
    </label>
    <label>
      <input
        type="radio"
        v-model="administrationDetails"
        value="Non-viable tissues or cells, or their derivatives, of animal origin"
      />
      Non-viable tissues or cells, or their derivatives, of animal origin
    </label>
    <label>
      <input
        type="radio"
        v-model="administrationDetails"
        value="Other non viable substances of biological origins (e.g., bacteria, fungi, viruses)"
      />
      Other non viable substances of biological origins (e.g., bacteria, fungi,
      viruses)
    </label>
    <label>
      <input type="radio" v-model="administrationDetails" value="Gases" />
      Gases
    </label>
    <label>
      <input
        type="radio"
        v-model="administrationDetails"
        value="Other substances"
      />
      Other substances
    </label>
    <div class="input-container" v-if="administrationDetails">
      <input
        v-model="administrationSubstance"
        placeholder="Specify substance"
        type="text"
      />
    </div>
  </div>
  <slot />
  <p>{{ getAdministersSubstancesText() }}</p>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import {
  getAdministersSubstancesText,
  useDeviceInfo,
} from "../composables/useDeviceInfo";

const deviceInfo = useDeviceInfo();

const administersSubstances = ref(deviceInfo.value.administersSubstances);
const administrationDetails = ref(deviceInfo.value.administrationDetails || "");
const administrationSubstance = ref(
  deviceInfo.value.administrationSubstance || ""
);

watch(administersSubstances, (newValue) => {
  deviceInfo.value.administersSubstances = newValue;
  administrationDetails.value = "";
  administrationSubstance.value = "";
});

watch(administrationDetails, (newValue) => {
  deviceInfo.value.administrationDetails = newValue;
});

watch(administrationSubstance, (newValue) => {
  deviceInfo.value.administrationSubstance = newValue;
});
</script>
