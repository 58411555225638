<template>
  <h2>Power source</h2>
  <p>
    Does the device have a power source other than that generated by the human
    body?
  </p>
  <label>
    <input type="radio" :value="true" v-model="isEnergySuppliedDevice" />
    Yes
  </label>
  <label>
    <input type="radio" :value="false" v-model="isEnergySuppliedDevice" />
    No
  </label>
  <div v-if="isEnergySuppliedDevice">
    <p style="margin-bottom: 8px">The device is powered by:</p>

    <p>The device has an external power supply:</p>
    <div class="subsection">
      <div>
        <label>
          <input type="radio" value="AC mains" v-model="powerDetails" /> AC
          mains
        </label>
      </div>
      <div>
        <label>
          <input type="radio" value="DC power supply" v-model="powerDetails" />
          DC power supply
        </label>
      </div>
      <div>
        <label>
          <input type="radio" value="USB power supply" v-model="powerDetails" />
          USB power supply
        </label>
      </div>
      <div>
        <label>
          <input
            type="radio"
            value="Wireless power supply"
            v-model="powerDetails"
          />
          Wireless power supply
        </label>
      </div>
      <div>
        <label>
          <input type="radio" value="Solar power" v-model="powerDetails" />
          Solar power
        </label>
      </div>
      <div>
        <label>
          <input type="radio" value="Other (External)" v-model="powerDetails" />
          Other (External)
        </label>
        <input
          v-if="powerDetails === 'Other (External)'"
          v-model="powerOtherExternal"
          type="text"
          placeholder="Specify other external power source"
        />
      </div>
    </div>

    <p>The device has an internal power supply:</p>
    <div class="subsection">
      <div>
        <label>
          <input
            type="radio"
            value="Disposable batteries"
            v-model="powerDetails"
          />
          Disposable batteries
        </label>
      </div>
      <div>
        <label>
          <input
            type="radio"
            value="Rechargeable batteries"
            v-model="powerDetails"
          />
          Rechargeable batteries
        </label>
      </div>
      <div>
        <label>
          <input type="radio" value="Other (Internal)" v-model="powerDetails" />
          Other (Internal)
        </label>
        <input
          v-if="powerDetails === 'Other (Internal)'"
          v-model="powerOtherInternal"
          type="text"
          placeholder="Specify other internal power source"
        />
      </div>
    </div>

    <label>
      <input type="radio" value="None of the above" v-model="powerDetails" />
      None of the above
    </label>
  </div>
  <slot />
  <p>{{ getEnergySuppliedDevice() }}</p>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import {
  getEnergySuppliedDevice,
  useDeviceInfo,
} from "../composables/useDeviceInfo";

const deviceInfo = useDeviceInfo();

const isEnergySuppliedDevice = ref(deviceInfo.value.isEnergySuppliedDevice);

const powerDetails = ref(deviceInfo.value.energySuppliedDevice || "");
const powerOtherExternal = ref("");
const powerOtherInternal = ref("");

watch(isEnergySuppliedDevice, (newValue) => {
  deviceInfo.value.isEnergySuppliedDevice = newValue;
  powerDetails.value = "";
  powerOtherExternal.value = "";
  powerOtherInternal.value = "";
});

watch(powerDetails, (newValue) => {
  if (newValue === "Other (External)") {
    deviceInfo.value.energySuppliedDevice = powerOtherExternal.value;
  } else if (newValue === "Other (Internal)") {
    deviceInfo.value.energySuppliedDevice = powerOtherInternal.value;
  } else {
    deviceInfo.value.energySuppliedDevice = newValue;
  }
});

watch(powerOtherExternal, (newValue) => {
  if (powerDetails.value === "Other (External)") {
    deviceInfo.value.energySuppliedDevice = newValue;
  }
});

watch(powerOtherInternal, (newValue) => {
  if (powerDetails.value === "Other (Internal)") {
    deviceInfo.value.energySuppliedDevice = newValue;
  }
});
</script>
<style scoped>
.subsection {
  margin-bottom: 20px;
}
</style>
