import { computed, inject, ref } from "vue";

export type PurposeType =
  | "Treatment"
  | "Alleviation"
  | "Compensation"
  | "Replacement"
  | "Modification"
  | "Diagnosis"
  | "Prevention"
  | "Monitoring"
  | "Prediction"
  | "Prognosis"
  | "Investigation"
  | "Control of conception"
  | "Support of conception"
  | "Cleaning devices"
  | "Disinfection of device"
  | "Sterilization of devices";

export interface Demographics {
  Age: string[];
  Gender: string[];
  Weight: string[];
  Height: string[];
  Ethnicity: string[];
  Race: string[];
  Nationality: string[];
  Language: string[];
  Skin_type: string[];
  Lifestyle_factor: string[];
  Other: string[];
}

export interface DeviceInfo {
  deviceName: string;
  standardizedNomenclature?: boolean;
  personalization: string;
  intendedUser: string[];
  invasiveness?: string;
  indication: string;
  purpose?: PurposeType;
  specification: string;
  purposeSpecificationDetail: string;
  comorbidityDetails: string;
  clinicalPictureDetails: string;
  isSingleUse?: boolean;
  energySuppliedDevice: string;
  isEnergySuppliedDevice?: boolean;
  energySuppliedPatient: string;
  isEnergySuppliedPatient?: boolean;
  microbialStateStatus?: boolean;
  radiation?: boolean;
  radiationType: string;
  demographics: Demographics;
  skipedDemographics: boolean;
  vulnerablePopulations: string[];
  skipedVulnerablePopulations: boolean;
  deviceType?: string;
  deviceTypeOptions: string[];
  isAccessory: boolean;
  compatibilities?: string;
  hasCompatibilities?: boolean;
  contraindicationsDetails: string;
  skipedContraindicationsDetails: boolean;
  precautionsDetails: string;
  skipedPrecautionsDetails: boolean;
  skipedClinicalPicture: boolean;
  substanceComposition?: boolean;
  compositionDetails?: string;
  compositionSubstance?: string;
  incorporatesSubstances?: boolean;
  incorporationDetails?: string;
  incorporationSubstance?: string;
  administersSubstances?: boolean;
  administrationDetails?: string;
  administrationSubstance?: string;
  removesSubstances?: boolean;
  removalDetails?: string;
  removalSubstance?: string;
  implant?: boolean;
}

export const deviceInfo = ref<DeviceInfo>({
  hasCompatibilities: undefined,
  deviceName: "",
  personalization: "",
  indication: "",
  intendedUser: [],
  purposeSpecificationDetail: "",
  specification: "",
  energySuppliedDevice: "",
  energySuppliedPatient: "",
  radiation: undefined,
  radiationType: "",
  demographics: {
    Age: [],
    Gender: [],
    Weight: [],
    Height: [],
    Ethnicity: [],
    Race: [],
    Nationality: [],
    Language: [],
    Skin_type: [],
    Lifestyle_factor: [],
    Other: [],
  },
  vulnerablePopulations: [],
  isAccessory: false,
  deviceTypeOptions: [],
  comorbidityDetails: "",
  clinicalPictureDetails: "",
  contraindicationsDetails: "",
  precautionsDetails: "",
  substanceComposition: undefined,
  compositionDetails: "",
  compositionSubstance: "",
  incorporatesSubstances: undefined,
  incorporationDetails: "",
  incorporationSubstance: "",
  administersSubstances: undefined,
  administrationDetails: "",
  administrationSubstance: "",
  removalDetails: "",
  removalSubstance: "",
  invasiveness: "",
  skipedClinicalPicture: false,
  skipedContraindicationsDetails: false,
  skipedDemographics: false,
  skipedPrecautionsDetails: false,
  skipedVulnerablePopulations: false,
  isEnergySuppliedDevice: undefined,
  isEnergySuppliedPatient: undefined,
});

export function useDeviceInfo() {
  const deviceInfoInjected = inject<typeof deviceInfo>("deviceInfo");
  if (!deviceInfoInjected) {
    throw new Error("Failed to inject deviceInfo");
  }
  return deviceInfoInjected;
}

export function formatPersonalization(
  personalization: string
): string | undefined {
  if (personalization !== "None of the above") {
    return `The device is ${personalization.toLowerCase()}`;
  }
}

export function getDeviceCompatibilitiesText(): string | undefined {
  return deviceInfo.value.hasCompatibilities && deviceInfo.value.compatibilities
    ? `The device has the following compatibilities: ${deviceInfo.value.compatibilities}.`
    : undefined;
}

export function getDeviceTypeText(): string | undefined {
  if (deviceInfo.value.deviceType)
    return `The generic device group of the device is: ${deviceInfo.value.deviceType}.`;
  else return undefined;
}

export function formatIntendedUser(intendedUsers: string[]): string {
  const messages = [];
  const healthcareProfessionals = intendedUsers.includes(
    "Healthcare professional"
  );
  const layUsers = intendedUsers.includes("Lay user");
  const patients = intendedUsers.includes("Patient is also a user");

  if (healthcareProfessionals) messages.push("healthcare professionals");
  if (layUsers) messages.push("lay users");
  if (patients) messages.push("the patient");

  return `The device is intended to be used by ${messages.join(" and ")}.`;
}

export function getDeviceName(): string | undefined {
  return deviceInfo?.value.deviceName
    ? `The device name is ${deviceInfo?.value.deviceName}`
    : undefined;
}

export function getDevicePersonalization(): string | undefined {
  return deviceInfo?.value.personalization
    ? formatPersonalization(deviceInfo.value.personalization)
    : undefined;
}

export function getDeviceInvasiveness(): string | undefined {
  if (
    deviceInfo?.value.invasiveness ===
    "The device is invasive but not surgically invasive"
  )
    return "The device is invasive";
  else if (deviceInfo?.value.invasiveness === "The device is not invasive")
    return undefined;
  else return deviceInfo.value.invasiveness;
}

export function getDeviceIntendedUser(): string | undefined {
  return deviceInfo?.value.intendedUser?.length
    ? formatIntendedUser(deviceInfo.value.intendedUser)
    : undefined;
}

export function getDevicePurpose(): string | undefined {
  const otherPurposes = [
    "Control of conception",
    "Support of conception",
    "Cleaning devices",
    "Disinfection of device",
    "Sterilization of devices",
  ];
  const purpose = deviceInfo.value.purpose ?? "";
  const detail = deviceInfo.value.purposeSpecificationDetail;

  if (otherPurposes.includes(purpose))
    return `The device is indicated for ${purpose.toLowerCase()}`;
  return purpose && detail
    ? `The device is indicated for ${purpose.toLowerCase()} of ${detail}`
    : undefined;
}

export function getEnergySuppliedDevice(): string | undefined {
  const energy = deviceInfo?.value.energySuppliedDevice;
  return energy
    ? `The device is powered by ${energy.toLowerCase()}.`
    : undefined;
}

export function getEnergySuppliedPatient(): string | undefined {
  const energy = deviceInfo?.value.energySuppliedPatient;
  return energy
    ? `The device supplies ${energy.toLowerCase()} to the patient.`
    : undefined;
}

export function getMicrobialStateStatus(): string | undefined {
  return deviceInfo?.value.microbialStateStatus
    ? `The device is sterile`
    : undefined;
}

export function getImplantText(): string | undefined {
  return deviceInfo?.value.implant ? `The device is implantable` : undefined;
}

export function getRadiation(): string | undefined {
  const radiation = deviceInfo?.value.radiation;
  const type = deviceInfo?.value.radiationType;
  return radiation && type ? `The device emits ${type}` : undefined;
}

export function getSingleUse(): string | undefined {
  return deviceInfo?.value.isSingleUse ? `The device is single-use` : undefined;
}

export function getDemographics(): string | undefined {
  const {
    Age,
    Gender,
    Weight,
    Height,
    Ethnicity,
    Race,
    Nationality,
    Language,
    Skin_type,
    Lifestyle_factor,
    Other,
  } = deviceInfo.value.demographics ?? {};
  const demoMessages = [];

  if (Age?.length) demoMessages.push(`<strong>age:</strong> ${Age.join(", ")}`);
  if (Gender?.length)
    demoMessages.push(`<strong>gender:</strong> ${Gender.join(", ")}`);
  if (Weight?.length)
    demoMessages.push(`<strong>weight:</strong> ${Weight.join(", ")}`);
  if (Height?.length)
    demoMessages.push(`<strong>height:</strong> ${Height.join(", ")}`);
  if (Ethnicity?.length)
    demoMessages.push(`<strong>ethnicity:</strong> ${Ethnicity.join(", ")}`);
  if (Race?.length)
    demoMessages.push(`<strong>race:</strong> ${Race.join(", ")}`);
  if (Nationality?.length)
    demoMessages.push(
      `<strong>nationality:</strong> ${Nationality.join(", ")}`
    );
  if (Language?.length)
    demoMessages.push(`<strong>language:</strong> ${Language.join(", ")}`);
  if (Skin_type?.length)
    demoMessages.push(`<strong>skin type:</strong> ${Skin_type.join(", ")}`);
  if (Lifestyle_factor?.length)
    demoMessages.push(
      `<strong>lifestyle factor:</strong> ${Lifestyle_factor.join(", ")}`
    );
  if (Other?.length)
    demoMessages.push(`<strong>other:</strong> ${Other.join(", ")}`);

  return demoMessages.length > 0
    ? `The device is intended for patients with ${demoMessages.join(", ")}.`
    : undefined;
}

export function getVulnerablePopulations(): string | undefined {
  return deviceInfo?.value.vulnerablePopulations &&
    deviceInfo?.value.vulnerablePopulations.length > 0
    ? `The device is intended for the following vulnerable populations ${deviceInfo?.value.vulnerablePopulations.join(
        ", "
      )}.`
    : undefined;
}

export function getComorbidityDetailsText(): string | undefined {
  return deviceInfo.value.comorbidityDetails
    ? `The device is intended for patients with: ${deviceInfo.value.comorbidityDetails}.`
    : undefined;
}

export function getClinicalPictureDetailsText(): string | undefined {
  return deviceInfo.value.clinicalPictureDetails
    ? `The device is intended for patients with: ${deviceInfo.value.clinicalPictureDetails}.`
    : undefined;
}

export function getContraindicationsText(): string | undefined {
  return deviceInfo.value.contraindicationsDetails
    ? `The device has the following contraindications: ${deviceInfo.value.contraindicationsDetails}.`
    : undefined;
}

export function getPrecautionsDetailsText(): string | undefined {
  return deviceInfo.value.precautionsDetails
    ? `The device has the following precautions and warning: ${deviceInfo.value.precautionsDetails}.`
    : undefined;
}

export function getSubstanceCompositionText(): string | undefined {
  return deviceInfo.value.substanceComposition &&
    deviceInfo.value.compositionDetails &&
    deviceInfo.value.compositionSubstance
    ? `The device is composed of the following substance:
    ${deviceInfo.value.compositionDetails} (${deviceInfo.value.compositionSubstance})`
    : undefined;
}

export function getRemovesSubstancesText(): string | undefined {
  return deviceInfo.value.removalSubstance &&
    deviceInfo.value.removalDetails &&
    deviceInfo.value.removesSubstances
    ? `The device removes the following substance from the body: ${deviceInfo.value.removalDetails} (${deviceInfo.value.removalSubstance})`
    : undefined;
}

export function getIncorporatesSubstancesText(): string | undefined {
  return deviceInfo.value.incorporatesSubstances &&
    deviceInfo.value.incorporationDetails &&
    deviceInfo.value.incorporationSubstance
    ? `The device incorporates the following substance: ${deviceInfo.value.incorporationDetails} (${deviceInfo.value.incorporationSubstance})`
    : undefined;
}

export function getAdministersSubstancesText(): string | undefined {
  return deviceInfo.value.administersSubstances &&
    deviceInfo.value.administrationDetails &&
    deviceInfo.value.administrationSubstance
    ? `The device administers the following substance: ${deviceInfo.value.administrationDetails} (${deviceInfo.value.administrationSubstance})`
    : undefined;
}

export const summary = computed(() => {
  const sections = [
    getDeviceName(),
    getDeviceTypeText(),
    getDevicePurpose(),
    getDemographics(),
    getVulnerablePopulations(),
    getClinicalPictureDetailsText(),
    getComorbidityDetailsText(),
    getContraindicationsText(),
    getPrecautionsDetailsText(),
    getDeviceIntendedUser(),
    getImplantText(),
    getDeviceInvasiveness(),
    getDevicePersonalization(),
    getSingleUse(),
    getMicrobialStateStatus(),
    getRadiation(),
    getEnergySuppliedDevice(),
    getEnergySuppliedPatient(),
    getDeviceCompatibilitiesText(),
    getSubstanceCompositionText(),
    getIncorporatesSubstancesText(),
    getAdministersSubstancesText(),
    getRemovesSubstancesText(),
  ];

  const validSections = sections.filter((section) => section !== undefined);

  return validSections.join("\n");
});
