import axios from "axios";

export const generateSummary = async (sentences: string): Promise<string> => {
  try {
    const response = await axios.post("/openai/v1/chat/completions", {
      model: "gpt-4o-mini",
      messages: [
        {
          role: "assistant",
          content: `Create a paragraph for the sentences prioritizing clarity and keeping all information, keep the formulation of the characteristics as close as possible as the formulations in the sentences: ${sentences} and please keep it short as possible.`,
        },
      ],
      max_tokens: 1500,
    });

    const content = response.data.choices[0].message?.content;
    if (content) {
      return content;
    } else {
      return "";
    }
  } catch (error) {
    console.error("Error generating summary:", error);
    return "";
  }
};
